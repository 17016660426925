<div class="alert-container" *ngIf="showAlert">
  <alert *ngIf="filteredAlert[0]?.AlertType === 'Informational'" class="alert-inf mt-2" type="info" [dismissible]="true">
    <div class="d-flex flex-row bd-highlight align-alert">
      <div class="d-flex align-items-md-center align-items-sm-start pt-1"><img class="mr-2 mb-1" height="20" width="20" src="../../../../assets/images/feature/info.png" alt="filtered alert info" /></div>
      <div class="bd-highlight"><span [innerHTML]="filteredAlert[0]?.AlertMessage"></span></div>
    </div>
  </alert>
  
  <alert *ngIf="filteredAlert[0]?.AlertType === 'Warning'" class="alert-warn mt-2" type="warning" [dismissible]="true">
    <div class="d-flex flex-row bd-highlight align-alert">
      <div class="d-flex align-items-md-center align-items-sm-start pt-1"><img class="mr-2 mb-1" height="20" width="20" src="../../../../assets/images/feature/warning.png" alt="filtered alert warning" /></div>
      <div class="bd-highlight"><span [innerHTML]="filteredAlert[0]?.AlertMessage"></span></div>
    </div>
  </alert>
  
  <alert *ngIf="filteredAlert[0]?.AlertType === 'Critical'" class="alert-err mt-2" type="error" [dismissible]="true">
    <div class="d-flex flex-row bd-highlight align-alert">
      <div class="d-flex align-items-centerd-flex align-items-md-center align-items-sm-start pt-1"><img class="mr-2 mb-1" height="20" width="20" src="../../../../assets/images/feature/error.png" alt="filtered alert error" /></div>
      <div class="bd-highlight"><span [innerHTML]="filteredAlert[0]?.AlertMessage"></span></div>
    </div>
  </alert>
</div>

<div class="border-btm d-flex justify-content-between">
  <div class="container-sub-nav">
    <nav class="navbar-expand-sm navbar-collapse" id="header-menu">
      <a href="https://www.dnb.com/" class="navbar-brand">
        <img decoding="async" class="dnb-full-logo" height="35" width="240" src="../../../../assets/images/common/dnb_logo.svg"
          alt="Dun & Bradstreet full logo"/>
        <img class="dnb-logo" height="35" width="30" src="../../../../assets/images/common/dnb_logo1.png"
          alt="Dun & Bradstreet logo" />
      </a>

    </nav>
  </div>
  <div class="header-end-item d-flex align-items-center">
    
    <div class="btn-group name-dropdown" *ngIf="showProductUserName; else loginDetails">
      <!-- <button id="notification-button" (click)="openNotification()" type="button" class="btn btn-link hover-light notification-button-loggedin"
          placement="bottom">
          <img *ngIf="shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon-black.png" alt="filtered alert info" />
          <img *ngIf="!shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon.png" alt="filtered alert info" />
          <span *ngIf="showCount && notificationCount > 0" class="notification-count">{{ notificationCount }}</span>
        </button> -->
      <div id="button-custom-html" class="btn btn-link dropdown-toggle loginfont product-user">
        Welcome, {{getUserName() || "User"}}
    </div>
    </div>
    <ng-template #loginDetails>
      <div class="btn-group name-dropdown" *ngIf="authService.isAuthenticated(); else loginButton" dropdown>
        <!-- <button id="notification-button" (click)="openNotification()" type="button" class="btn btn-link hover-light notification-button-loggedin"
          placement="bottom">
          <img *ngIf="shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon-black.png" alt="filtered alert info" />
          <img *ngIf="!shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon.png" alt="filtered alert info" />
          <span *ngIf="showCount && notificationCount > 0" class="notification-count">{{ notificationCount }}</span>
        </button> -->
        <button id="button-custom-html" dropdownToggle type="button" class="btn btn-link dropdown-toggle loginfont"
          aria-controls="dropdown-custom-html">
          Welcome, {{getUserName() || "User"}}
          <span class="pl-1">
            <i class="fa" [ngClass]="isUserDropdownOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
          </span>
        </button>
        <ul id="dropdown-custom-html" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
          aria-labelledby="button-custom-html">
          <li role="menuitem"><a class="dropdown-item" (click)="doLogout()">Logout</a></li>
        </ul>
      </div>
      <ng-template #loginButton>
        <div class="login">
          <!-- <button id="notification-button" (click)="openNotification()" type="button" class="btn btn-link hover-light notification-button"
          placement="bottom">
          <img *ngIf="shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon-black.png" alt="filtered alert info" />
          <img *ngIf="!shownNewNoticationIcon" class="mb-1" height="20" width="20" src="../../../../assets/images/feature/notification-icon.png" alt="filtered alert info" />
          <span *ngIf="showCount && notificationCount > 0" class="notification-count">{{ notificationCount }}</span>
          </button> -->
          <button id="login-button" type="button" class="btn btn-link hover-light login-button"
          #pop="bs-popover" [popover]="signInPopover"
          placement="bottom" [outsideClick]="true">Log In</button>
          <ng-template #signInPopover>
            <div class="row">
              <div class="col-sm-12 col-md-6 pl-4 pr-3 divider">
                <div class="p-0">
                <div class="text-3xl sign-in-title mt-2">Existing User</div>
                <p class="text-md pt-3 mb-0 pl-0 popover-content login-text-color">
                  If you have previously registered for the Digital Service Center, you can use your existing credentials to login here.
                </p>
                <button
                type="button"
                class="btn btn-primary btn-height mt-2 mb-3"
                (click)=doLogin()
              >
                Log In
              </button>
              </div>
              </div>
              <div class="col-sm-12 col-md-6 pl-4 pr-3 divider-small">
                <div class="p-0">
                  <div class="text-3xl sign-in-title mt-2">New User</div>
                  <p class="text-md pt-3 mb-0 pl-0 popover-content login-text-color">
                    If this is your first time to login to the Digital Service Center, please click on the <strong class="text-lg">Create User</strong> button to get registered.
                  </p>
                    <button
                      type="button"
                      class="btn btn-primary btn-height mt-2 mb-3"
                      (click)="pop.hide();createUserForm()"
                    >
                      Create User
                    </button>
                </div>
              
  
              </div>
          
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </div>

    <div class="col-sm-8 display-inline text-align-right pl-0 pr-0 display-none">
      <button type="button" class="btn btn-light lg-btn-width mb-3 mt-3 box-min-height light-btn drift-open-chat display-none" #myDiv id="drift-open-chat" tabindex=0 onclick="DriftBot()" onkeypress="DriftBot()">
        Chat
      </button>
      <div class="load-drift display-none" #myDrift id="load-drift" tabindex=0 onclick="playBookLoad()" onkeypress="playBookLoad()">
        Load Drift
      </div>
      <div class="hide-drift display-none" #hideDrift id="hide-drift" tabindex=0 onclick="playBookHide()" onkeypress="playBookHide()">
        Hide Drift
      </div>
      <div class="logout-drift display-none" #logoutDrift id="logout-drift" tabindex=0 onclick="logoutDrift()" onkeypress="logoutDrift()">
        Logout Drift
      </div>
    </div>
</div>
