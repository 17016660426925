import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { HomeService } from 'src/app/feature/user/services/home/home.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent implements OnInit {
  public showLoader = false;
  public alertList: Array<any> = [];
  public filteredAlert: Array<any> = [];
  public serverError = false;
  public featureList: Array<any>;
  public helpfulLinksList: Array<any>;
  private unsubscribe$ = new Subject();
  public createUser = false;
  public locationData: any;
  public locationIp: any;
  public locationCountry: any;
  public showNoNewNotification: boolean = false;

  constructor(
    public homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public http: HttpClient,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    //this.getLocationCountry();
    this.route.queryParams?.subscribe(
      (params: Params) => {
        if(this.router.url.indexOf('/home/alert') > -1) {
          this.getHomeDataTask();
          this.createUser = true;
        }
      });
      this.homeService.data$.subscribe(
        (data) => {
          if (data === "createUserClick") {
            this.scrollContent();
          }
        }
      );
  }

  selectAll: boolean = false;
  selectedAlerts: Set<string> = new Set();

  toggleSelectAll() {
    if (this.selectAll) {
      this.filteredAlert.forEach(alert => this.selectedAlerts.add(alert.AlertId));
    } else {
      this.selectedAlerts.clear();
    }
  }
  
  clearSelected() {
    this.showLoader = true;
    console.log('Loader shown:', this.showLoader);
    // this.cdr.detectChanges(); 
    this.filteredAlert = this.filteredAlert.filter(alert => !this.selectedAlerts.has(alert.AlertId));
  
    const clearedAlertIds = Array.from(this.selectedAlerts);
    console.log(clearedAlertIds, "cleared alert IDs");
    const clearedNotificationsCookie = this.getCookie('clearedNotifications');
    const previousClearedAlertIds = clearedNotificationsCookie ? JSON.parse(clearedNotificationsCookie) : [];
    const allClearedAlertIds = [...new Set([...previousClearedAlertIds, ...clearedAlertIds])];
    document.cookie = `clearedNotifications=${JSON.stringify(allClearedAlertIds)}; path=/; expires=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toUTCString()}`;
    this.selectedAlerts.clear();
    this.selectAll = false;
    this.showNoNewNotification = this.filteredAlert.length === 0;
    setTimeout(() => {
      this.showLoader = false;
      console.log('Loader hidden:', this.showLoader);
      // this.cdr.detectChanges(); 

    }, 300);
  }
  
  onCheckboxChange(alertId: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
  
    if (isChecked) {
      this.selectedAlerts.add(alertId);
    } else {
      this.selectedAlerts.delete(alertId);
    }
    this.selectAll = this.filteredAlert.every(alert => this.selectedAlerts.has(alert.AlertId));
  }
  
  getAlertClass(type: string): string {
    switch (type) {
      case 'Informational':
        return 'alert-information';
      case 'Warning':
        return 'alert-warning-custom';
      case 'Critical':
        return 'alert-critical';
      default:
        return 'alert-default';
    }
  }

  ngAfterViewInit(): void {
    this.scrollContent();
  }

  /**
   * Method to scroll the page to the create user form
   */
  public scrollContent(): void {
      const el: any = document.getElementById("create-user");
      el?.scrollIntoView();
  }

  private getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
}

  /**
   * @description Method to get channelMap data from API
   */
  public getHomeDataTask (): void {
    this.homeService.getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          this.showLoader = false;
          this.alertList = resp?.alertList[0]?.Channel_List;
          console.log(this.alertList,"alert list1");
          const clearedNotificationsCookie = this.getCookie('clearedNotifications');
          const clearedNotificationIds = clearedNotificationsCookie ? JSON.parse(clearedNotificationsCookie) : [];

          // Filter out cleared notifications
          this.filteredAlert = this.alertList.filter(alert =>
            alert.AlertStatus === 'Active' && !clearedNotificationIds.includes(alert.AlertId)
          );
          console.log(this.filteredAlert.length,"count el");
          
          
          // this.filteredAlert = this.alertList.filter(alert => alert.AlertStatus === 'Active');
          this.selectedAlerts.clear();
          this.selectAll = false;
          this.sharedService.updateNotificationCount(this.filteredAlert.length);

          const channelMapData = resp.channelList;
          this.featureList = resp?.featureList[0]?.Channel_List;
          this.helpfulLinksList = resp?.helpfulLinksList[0]?.Channel_List;
          //this.initData(channelMapData);
        }
      },
        (err) => {
          this.showLoader = false;
          if (err?.error?.StatusCode === "500") {
            this.serverError = true;
          }
        });
  }

}
