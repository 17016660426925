<div class="ng-autocomplete search-item">
    <ng-autocomplete 
    [data]="data" 
    [searchKeyword]="keyword" 
    [customFilter]="disableFilter"
    (selected)='selectEvent($event)'
    (inputChanged)='onChangeSearch($event)'
    (inputCleared)="searchCleared()"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [debounceTime]="300"
    placeholder="Search Support Documentation by Keyword"
    [(ngModel)]="searchedKeyword"
    (input)="validateInput($event)"
    (keyup)="keyupEvent($event, searchedKeyword)">
  </ng-autocomplete>
 
    <div class="remaining-characters">
      Remaining characters: {{100 - (searchedKeyword?.length || 0)}}
    </div>
 
  
  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.Title"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound >
    <div class="searchHeader" *ngFor="let item of notFoundMessage;let i=index;" >
      <div class="searchAlternate" [innerHTML]="item" *ngIf="searchedKeyword?.length > 2" 
        (click)="redirectToCommonKeywords(searchedKeyword,i)"></div>

  </div>
  </ng-template>
</div>