import { Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../search/services/search/search.service';
import { UserService } from "src/app/feature/user/services/user.service";
import { AppUtil } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-search',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  public keyword = 'Title';
  public data: Array<any> = [];
  private unsubscribe$ = new Subject();
  public searchedKeyword:any = "";
 //public notFoundMessage: string;
 public notFoundMessage: Array<any> = [];
  
  constructor( 
    private searchService: SearchService,
    private uService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchService.data$.subscribe(
      (search) => {
        if (search.data === true) {
          this.searchCleared();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    
    // const listElement = document.querySelector('.autocomplete-container');
    // listElement?.removeAttribute('aria-expanded');

    // const listElement = document.querySelector('.search-item');
    // listElement?.setAttribute('aria-label', 'searchItem');
    
    const suggestionEle = document.querySelector('#suggestions ul');
    suggestionEle?.setAttribute('role', 'option');
    
    const suggestionHistoryULEle = document.querySelector('#suggestions-history ul');
    suggestionHistoryULEle?.setAttribute('role','option');
    suggestionHistoryULEle?.setAttribute('aria-label','suggestionsHistory');
    
    const suggestionHistoryEle = document.querySelector('#suggestions-history.is-visible');
    suggestionHistoryEle?.setAttribute('aria-label','suggestionsHistoryVisible');
    
  }
  
  ngOnChanges(): void {}

  /**To disable the default filter of autocomplete and return all results from api */
  disableFilter = (items: any[]) => items;

  /**
   * Method triggered when item is selected
   * @param item Event
   */
  public selectEvent(item: any): void {
    const firstItem = this.data[0];
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    const keyword = this.searchedKeyword;
    if (item === firstItem) {
      this.router.navigate(["/search/search-results"],
        { queryParams: { keyword: keyword, id: currentTimeInSeconds } });
    } else if (item !== firstItem) {
      this.router.navigate(["/search/article-content"],
        { queryParams: { keyword: keyword, title: item.Title, id: currentTimeInSeconds } });
    }
  }

  /**
   * Method triggered when input changed
   * @param keyword Searched keyword
   */
  public onChangeSearch(keyword: string): void {
    this.data = [];
    this.notFoundMessage = [];
    const searchedKeyword = keyword.trim();
    const isNum = searchedKeyword.match(/^[0-9]+$/);
    if (isNum == null && searchedKeyword?.length > 2) {
      this.getTitles(searchedKeyword);
    } else if (isNum != null && searchedKeyword?.length > 2) {
      this.getTitles(searchedKeyword);
    } else {
      this.data = [];
    }
  }

  validateInput(event: any): void {
    const input = event.target;
    if (input.value.length > 100) {
      input.value = input.value.substring(0, 100);
      this.searchedKeyword = input.value;
    }
  }

  /**
   * Method triggered when search is cleared
   */
  public searchCleared(): void {
    this.notFoundMessage = [];
    this.data = [];
  }

  /**
   * @description Method to get titles
   */
  public getTitles (keyword: string): void {
    this.notFoundMessage = [];
    this.searchService.getTitles(keyword)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          if (resp?.searchRecords?.length > 0) {
            this.data = resp.searchRecords;
            this.data?.unshift({"Title": '"' + keyword + '"'});
          }
        }
      },
        (err: any) => {
          if (err?.status === 400 && err?.error?.MessageCode === "001") {
            this.notFoundMessage = err?.error?.SuggestedMessage;
          }
        });
  }

  /**
   * Method to navigate to search results page when enter key is clicked
   * @param event Event
   * @param searchedKeyword Searched keyword
   */
  public keyupEvent(event: any, searchedKeyword: string): void {
    this.data = [];
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    if (event?.keyCode === 13 && searchedKeyword?.length > 2) {
      this.notFoundMessage = [];
      this.searchService.getTitles(searchedKeyword)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
          if (resp) {
            if (resp?.searchRecords?.length > 0) {
              this.data = resp.searchRecords;
              this.router.navigate(["/search/search-results"],
                  { queryParams: { keyword: searchedKeyword, id: currentTimeInSeconds } });
            }
          }
        },
          (err: any) => {
            if (err?.status === 400 && err?.error?.MessageCode === "001") {
              this.notFoundMessage = err?.error?.SuggestedMessage;
              if (this.notFoundMessage) {
                this.router.navigate(["/search/common-keywords"],
                  { queryParams: { keyword: searchedKeyword, id: currentTimeInSeconds } });
              }
            }
          });
    }
    const isNum = searchedKeyword.match(/^[0-9]+$/);
    if (event?.keyCode === 8 && isNum != null && searchedKeyword?.length <= 7) {
      setTimeout(() => {
        this.notFoundMessage = [];
      }, 1000);
    }
  }

  // public omit_special_char(event: any): any {   
  //  const k = event.charCode;
  //  return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  // }

  /**
   * Method to navigate to common keywords page/DUNS directory page on click
   * @param searchedKeyword Searched keyword
   */
  public redirectToCommonKeywords(searchedKeyword: string, index :number): void {
    
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    if ((this.notFoundMessage[0] === "Find a Company or Dun & Bradstreet's D-U-N-S Number?") && index==0) {

       //   for GA
     this.uService.emitEvent(
      searchedKeyword,
      "Searched Keyword",
      "SearchSuccess",
      8
    );
    // for GA
    window.open(
      "https://www.dnb.com/site-search-results.html#AllSearch=" +
        searchedKeyword +
        "&BusinessDirectoryPageNumber=1&BusinessDirectorySearch=" +
        searchedKeyword +
        "&CompanyProfilesPageNumber=1&CompanyProfilesSearch=" +
        searchedKeyword +
        "ContactProfilesPageNumber=1&ContactDirectoryPageNumber=1" +
        "&DAndBMarketplacePageNumber=1&DAndBMarketplaceSearch=" +
        searchedKeyword +
        "IndustryPageNumber=1&MarketplacePageNumber=1&SiteContentPageNumber=1" +
        "&SiteContentSearch=" +
        searchedKeyword +
        "&tab=Business%20Directory",
      "_blank"
    );
  } else if (this.notFoundMessage[0] === "Get a Status on an Existing Support Case" && index==0) {
      //   for GA
      this.uService.emitEvent(
        searchedKeyword,
        "Searched Keyword",
        "SearchSuccess",
        8
      );
      // for GA
      AppUtil.saveToSession("caseNumber", this.searchedKeyword);
      this.router.navigate(["/search/article-content"],
      { queryParams: { keyword: this.notFoundMessage[0], title: this.notFoundMessage[0], id: currentTimeInSeconds } });
    } else  if(this.notFoundMessage[1] === "Find a Company or Dun & Bradstreet's D-U-N-S Number?" && index==1) {

      //   for GA
    this.uService.emitEvent(
     searchedKeyword,
     "Searched Keyword",
     "SearchSuccess",
     8
   );
   // for GA
   window.open(
    "https://www.dnb.com/site-search-results.html#AllSearch=" +
      searchedKeyword +
      "&BusinessDirectoryPageNumber=1&BusinessDirectorySearch=" +
      searchedKeyword +
      "&CompanyProfilesPageNumber=1&CompanyProfilesSearch=" +
      searchedKeyword +
      "ContactProfilesPageNumber=1&ContactDirectoryPageNumber=1" +
      "&DAndBMarketplacePageNumber=1&DAndBMarketplaceSearch=" +
      searchedKeyword +
      "IndustryPageNumber=1&MarketplacePageNumber=1&SiteContentPageNumber=1" +
      "&SiteContentSearch=" +
      searchedKeyword +
      "&tab=Business%20Directory",
    "_blank"
  );
   } else {
      this.router.navigate(["/search/common-keywords"],
      {queryParams: {keyword: searchedKeyword, id: currentTimeInSeconds}});
    }
  }

}
