import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HomeService } from "src/app/feature/user/services/home/home.service";
import { UserService } from "src/app/feature/user/services/user.service";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { CommonService } from "src/app/shared/services/common/common.service";
import { AppUtil } from "../../utils/utils";
import { Location } from "@angular/common";
import { ProductService } from "src/app/feature/product/services/product.service";
import { SearchService } from "src/app/feature/search/services/search/search.service";
import { SharedService } from "src/app/shared/services/shared/shared.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  public isUserDropdownOpen = false;
  public alertList: Array<any> = [];
  private unsubscribe$ = new Subject();
  public filteredAlert: Array<any> = [];
  @ViewChild('myDiv') myDiv: ElementRef<HTMLInputElement>;
  @ViewChild('logoutDrift') logoutDrift: ElementRef<HTMLInputElement>;
  public showProductUserName = false;
  public userDetails: any;
  public showAlert = true;
  notificationCount: number = 0;
  public shownNewNoticationIcon: boolean;
  public showCount: boolean = true;

  constructor(
    public userService: UserService, 
    public authService: AuthService, 
    public commonService: CommonService, 
    public homeService: HomeService,
    private router: Router,
    private productService: ProductService,
    private searchService: SearchService,
    private sharedService: SharedService
    ) { }

  ngOnInit (): void {
    const isNotificationSeen = this.getCookie('notificationSeen');
    console.log(isNotificationSeen,"isNotificationSeen");
    if(isNotificationSeen === 'true') {
      console.log("true");
      this.showCount = false;
      this.notificationCount = 0
      this.filteredAlert.length === 0
    }
    
    this.getAlertList();
    
    this.showProductUserName = false;
    this.productService.data$.subscribe(
      (product) => {
        if (product?.customerDetails) {
          this.userDetails = product.customerDetails;
        }
        if (product?.url?.indexOf('/product') > -1) {
          this.showAlert = false;
          this.showProductUserName = true;
        } else if ( product === 'backNavigation') {
          this.ngOnInit();
          this.showAlert = true;
        }
      }
    );
    this.searchService.urlData$.subscribe(
      (search) => {
        if (search?.url?.indexOf('/search') > -1) {
          this.showAlert = false;
        } else if ( search === 'backNavigation') {
          this.ngOnInit();
          this.showAlert = true;
        }
      }
    );
  }

  ngAfterViewInit () {
    AppUtil.saveToSession('driftDiv', this.myDiv.nativeElement.id);
    AppUtil.saveToSession('logoutDrift', this.logoutDrift.nativeElement.id);
  }

  /**
 * @description Method to do login action
 */
  public doLogin (): void {
    const prevTab =  this.commonService.getStringFromSession("previousTab");
    this.userService.doLogin(prevTab);
  }

  /**
* @description Method to do logout action
*/
  public doLogout (): void {
    if (this.authService.isAuthenticated()) {
      localStorage.removeItem("tab_details");
      const prevTab = this.commonService.getStringFromSession("previousTab");
      this.authService.doLogout(prevTab);
    }
  }

  /**
* @description Method to do logout action
*/
  public getUserName (): string {
    if(this.showProductUserName) {
      return this.userDetails?.firstName;
    } else {
      const userData = this.userService.getUserData();
      return userData["firstName"];
    }
  }

    /**
   * @description Method to get alert list from channelMap API
   */
  public getAlertList (): void {
    this.homeService.getHomeData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp) {
          const openedNotificationIds = this.getOpenedNotificationIds();
          this.shownNewNoticationIcon = true;
          this.alertList = resp?.alertList[0]?.Channel_List;
          for (let i = 0 ; i < this.alertList?.length; i++) {
            this.filteredAlert =  this.alertList.filter(alert => {
              return alert.AlertStatus === 'Active';
            });
            this.checkForNewNotifications();
          }
        }
      });
  }

   private checkForNewNotifications(): void {
    const openedNotificationIds = this.getOpenedNotificationIds();
    const newNotifications = this.filteredAlert.filter(alert => !openedNotificationIds.includes(alert.AlertId));
    this.shownNewNoticationIcon = newNotifications.length > 0;
    if(this.shownNewNoticationIcon){
      console.log("new not");
      
      this.showCount = true
    }
    this.notificationCount = newNotifications.length;
  }
  

  openNotification(): void {
    const openedNotificationIds = this.getOpenedNotificationIds();
    const newOpenedIds = [...openedNotificationIds, ...this.filteredAlert.map(alert => alert.AlertId)];
    this.setOpenedNotificationIds(newOpenedIds);
    this.notificationCount = 0;
    this.shownNewNoticationIcon = false;
    document.cookie = `notificationSeen=true; path=/; expires=${this.getCookieExpiryDate()}`;
    this.router.navigate(['home/alert']);
  }

private getOpenedNotificationIds(): string[] {
  const cookieValue = this.getCookie('openedNotifications');
  return cookieValue ? JSON.parse(cookieValue) : [];
}

private setOpenedNotificationIds(ids: string[]): void {
  const uniqueIds = [...new Set(ids)];
  const cookieValue = JSON.stringify(uniqueIds);
  document.cookie = `openedNotifications=${cookieValue}; path=/; expires=${this.getCookieExpiryDate()}`;
}

/**
 * Get a cookie value by name
 */
private getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
}

/**
 * Get a far future expiry date for the cookie
 */
private getCookieExpiryDate(): string {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2); // Cookie valid for 2 months
  return date.toUTCString();
}


  /**
   * Method to navigate to create user form for sign in
   */
  public createUserForm(): void {
    this.homeService.emitData("createUserClick");
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds
    this.router.navigate(["home/create-user"], { queryParams: { id: currentTimeInSeconds } });
  }

  // public productPage(): void {
  //   this.router.navigate(["product"], { queryParams: { reason: "Cancel Contract", cust_details: "ddewfwfwefdsvds" } });
  // }
}
