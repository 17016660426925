import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private searchResultData: any[];
  private searchRegResultData: any[]
  private companyRegNumValue: string;
  private defaultCountry: string;
  private languageChangedSubject = new Subject<string>();
  private notificationCountSource = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSource.asObservable();

  languageChanged$ = this.languageChangedSubject.asObservable();

  constructor(private http: HttpClient) { }

  setSearchResultData(data: any[]): void {
    this.searchResultData = data;
  }

  getSearchResultData(): any[] {
    return this.searchResultData;
  }

  setSearchResultByRegNumData(data: any[]): void {
    this.searchRegResultData = data;
  }

  getSearchResultByRegNumData(): any[] {
    return this.searchRegResultData;
  }

  setCompanyRegNumValue(value: string): void {
    this.companyRegNumValue = value;
  }

  getCompanyRegNumValue(): string {
    return this.companyRegNumValue;
  }

  setDefaultCountry(value: string): void {
    this.defaultCountry = value
  }

  getDefaultCountry(): string {
    return this.defaultCountry
  }

  emitLanguageChanged(languageCode: string) {
    this.languageChangedSubject.next(languageCode);
  }

  getReferrerLink(language: string): Observable<string> {
    return this.http.get<{ referrerLink: string }>(`localization/${language}.json`).pipe(
      map(data => data.referrerLink)
    );
  }

   updateNotificationCount(count: number): void {
    this.notificationCountSource.next(count);
  }
}
