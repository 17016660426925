<div *ngIf="showLoader" class="dnbi-loader-container">
  <div class="dnbi-loader-gif"></div>
  <img [class.lazy]="true" src="../assets/images/common/dnb-loading.gif" height="60" width="60"
    alt="loading indicator" />
</div>
<div *ngIf="!serverError" class="d-flex flex-column main-container">
  <div class="banner-container">
    <div class="frequent-title-color text-lg banner-font banner-margin text-center">
      Digital Service Center
    </div>
  </div>

  <app-help-topics [featureList]="featureList"></app-help-topics>
  <div class="d-flex align-items-center justify-content-between alert-padding">
    <div class="d-flex align-items-center">
      <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleSelectAll()" id="select-all" />
      <label for="select-all" class="text-3xl ml-3 dark-text">Alert & Notifications</label>
    </div>
    <div>
      <button type="button" [routerLink]="['/home']" class="btn btn-primary btn-height ml-3">Home</button>
      <button (click)="clearSelected()" class="btn btn-danger btn-height ml-2">Clear</button>
    </div>
  </div>

  <div *ngFor="let alerts of filteredAlert; let i = index" class="notification-row alert-padding pt-2">
    <div class="d-flex align-items-center">
      <input type="checkbox" [checked]="selectedAlerts.has(alerts.AlertId)" id="notif-{{ i }}"
        (change)="onCheckboxChange(alerts.AlertId!, $event)" />
      <div [ngClass]="getAlertClass(alerts.AlertType)" class="ml-2 pt-3 pl-3 pb-3 pr-3">
        <span [innerHTML]="alerts.AlertMessage"></span>
      </div>

    </div>
  </div>
  <div *ngIf="showNoNewNotification" class="notification-row alert-padding pt-2 ml-2 pl-2 mr-2 pr-2">
    <div class="d-flex align-items-center pb-3">
      <div [ngClass]="getAlertClass('alert-default')">
        <span>No new Notifications available.</span>
      </div>
    </div>
  </div>

  <!-- <div class="alert-padding ml-5">
        Alert & Notifications
        <span><button>Home</button></span>
        <span><button>Clear</button></span>
    </div>
    <div class="alert-border alert-padding mt-2 mb-3">
        
        <div class=" d-flex flex-row bd-highlight align-alert">
            
          <div class="bd-highlight mt-3 mb-3"><span class="mt-2 mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </span></div>
        </div>
    </div> -->
</div>