<div class="help-topics-container">
  <div class="text-3xl semi-bold title-label d-flex justify-content-md-between 
  justify-content-lg-between">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-8 how-can-help">How can we help you?</div>
      <div class="col-xs-12 col-sm-8 col-md-4 app-search-bar">
        <app-search></app-search>
      </div>
      <div class="col-xs-12 col-sm-6 how-can-help-mobile">How can we help you?</div>
    </div>
  </div>
  </div>
  <div class="displayhide">
    <div class="d-flex flex-column flex-sm-row help-topics align-items-stretch justify-content-sm-around">
      <div class="col-md help-topic justify-content-center d-flex text-xl  text-md align-items-center"
        *ngFor="let fList of featureList; let i = index">
            <a class="tooltip-tile" (click)="redirectTo(fList)">
              <span class="feature-icon"><fa-icon class="feature-icon" [icon]="['fas', icons[i]]"></fa-icon></span>
              <span class="text-color">{{fList?.FL_Name}}<fa-icon class="angle-right-icon" [icon]="['fas', 'angle-right']"></fa-icon></span>
              
            </a>
       
      </div>
    </div>
  </div>
  <div class="displayhideindestops">
    <div class="d-flex flex-row help-topics align-items-stretch justify-content-between">
      <div class="justify-content-center d-flex text-xl help-topic text-md align-items-center"
        *ngFor="let fList of featureList?.slice(0,3); let i = index">
        <a class="tooltip-tile" (click)="redirectTo(fList)">
          <span class="feature-icon"><fa-icon class="feature-icon" [icon]="['fas', icons[i]]"></fa-icon></span>
          <span class="text-color">{{fList?.FL_Name}}<fa-icon class="angle-right-icon" [icon]="['fas', 'angle-right']"></fa-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
